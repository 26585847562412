<template>
    <div class="mainPage">
        <div class="box">
            <div class="content">
                <div class="mainContainer">
                    <div class="topNav">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/navbackicon.png"
                            alt="">
                        <span>安全教育培训-火灾逃生与应急处置(二).mp4</span>
                        <dd>知识点</dd>
                    </div>
                    <div class="teacherContent">
                        <div class="teacherContentLeft">
                        </div>

                        <div class="teacherContentRight">
                            <div class="teacherContentRightTop">
                            </div>
                            <div class="topDesInfo">
                            </div>
                            <div class="bottomBtn">
                                <div class="learnBtn" @click="showBGStepAction">学习</div>
                                <div class="jumpBtn">跳过背景介绍，了解大纲</div>
                            </div>

                        </div>

                    </div>



                    <div v-show="isShowBG" class="teacherContent marginTOP23">
                        <div class="teacherContentLeft marginTOP25">

                        </div>

                        <div class="teacherContentRightNew">

                            <div v-show="isShowTearchGiftNewFirstSHOW" class="courseMainContentleftnew"
                                :class="{ 'animate-teachernew': isShowTearchGiftNewFirst }">
                                <div class="imageTeacher">

                                </div>
                                <div class="courseBGInfo">
                                </div>
                            </div>

                            <div v-show="isShowTearchGiftNewFirstImage" class="courseMainContentrighttnew"
                                :class="{ 'animate-teachernewright': isShowTearchGiftNewFirstImage }">
                                <el-image
                                    src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseBG.png"
                                    style="width: 458px;height: 257px;">
                                </el-image>
                            </div>

                            <!-- <div class="topFlex width100">
                                <div class="teacherContentRightTopC marginTOP25"
                                    :class="{ 'animate-left': animateOne }">
                                    <div class="teacherContentRightTop">

                                    </div>
                                    <div class="courseBGInfo">
                                    </div>
                                </div>

                                <div v-show="isShowImageRight" :class="{ 'animate-right': isShowImageRight }"
                                    class="imgbg">
                                    <el-image
                                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseBG.png"
                                        style="width: 458px;height: 257px;">
                                    </el-image>
                                </div>

                            </div> -->


                        </div>

                    </div>

                    <div v-show="isShowBG" class="bottomBtnOne">
                        <div class="learnBtn" @click="showOutLineAction">学习</div>
                        <div class="learnBtn">下一节</div>
                        <div class="learnBtn">测验一下</div>
                        <div class="learnBtn">知识点</div>

                    </div>


                    <!--大纲内容开始-->

                    <div v-show="isShowOutLine" class="outLineDiv">
                        <div class="outLineLeft">

                        </div>
                        <div class="outLineRight">
                            <div class="outLineRightTop">

                            </div>
                            <div class="outLineDesInfo">
                                <div class="outlineTitle">课程大纲</div>
                                <div v-for="(chapter, index) in outLineData" :key="index" class="chapter"
                                    v-show="isChapterVisible(index)">
                                    <h2 class="chapter-title">{{ chapter.title }}</h2>
                                    <p v-html="chapter.displayContent"></p>
                                </div>
                            </div>
                            <div class="bottomBtnOne marginLeftZero">
                                <div class="learnBtn" @click="learnOutLineOne">学习</div>
                                <div class="learnBtn">下一节</div>
                                <div class="learnBtn">测验一下</div>
                                <div class="learnBtn">学习大纲</div>
                            </div>
                        </div>

                    </div>
                    <!--大纲内容结束-->


                    <!--大纲某一个课程开始-->
                    <div v-show="isShowOutLineOne" class="outLineDiv">
                        <div class="outLineLeft">

                        </div>
                        <div class="outLineRight">
                            <div class="outLineRightTop">
                            </div>
                            <div class="outLineDesInfo">
                                {{ chapter.displayContent }}
                            </div>
                            <div class="bottomBtnOne marginLeftZero">
                                <div class="learnBtn" @click="isShowTearchAnimationClick">学习</div>
                                <div class="learnBtn">下一节</div>
                                <div class="learnBtn">测验一下</div>
                                <div class="learnBtn">学习大纲</div>
                            </div>
                        </div>

                    </div>
                    <!--大纲某一个课程结束-->

                    <!--学习课程视频-->
                    <div v-show="isShowContentNew" class="outLineDiv">
                        <div class="outLineLeft" @click="isShowTearchAnimationClick">

                        </div>
                        <div class="courseMainContent">
                            <div v-show="isShowTextLeftNew" class="courseMainContentleftnobg"
                                :class="{ 'animate-teacher': isShowTextLeftNewAnimation }"
                                v-html="enText.displayContent">
                            </div>

                            <div v-show="isShowTearchGiftNew" class="courseMainContentleft"
                                :class="{ 'animate-teacher': isShowTearchAnimation }">
                            </div>

                            <div v-show="courseImageShow" class="courseMainContentright"
                                :class="{ 'animate-content': courseImageShow }">
                                <el-image
                                    src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/contentImage.png"
                                    style="width: 891px;height: 502px;">
                                </el-image>

                            </div>

                            <div v-show="showVideoContent" class="courseMainContentright"
                                :class="{ 'animate-content': showVideoContent }">
                                <video width="891" height="502" controls>
                                    <source
                                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/changjianjiashitufa.mp4"
                                        type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>

                            </div>

                            <div v-show="coursMainTextShow" class="courseMainContentright flexLeft"
                                :class="{ 'animate-content': coursMainTextShow }"
                                v-html="contentChapter.displayContent">
                            </div>


                            <div v-show="coursMainExamShow" class="courseMainContentright flexLeft"
                                :class="{ 'animate-content': coursMainExamShow }">

                                <div class="examContent">
                                    <div class="examOptions">
                                        <div class="examOptionsTitle">
                                            下列哪起事件不是由车辆自身故障引起的？
                                            <div class="options" @click="showExamAnswer">
                                                A. 2019年6月1日刘先生的转向系统故障
                                            </div>
                                            <div class="options" @click="showExamAnswer">
                                                B. 2018年11月3日重型大挂车因刹车失灵的事故
                                            </div>
                                            <div class="options" @click="showExamAnswer">
                                                C. 2016年8月19日越野车因爆胎的翻车事故
                                            </div>
                                            <div class="options" :class="isShowExamAnswer ? 'isRightColor' : ''"
                                                @click="showExamAnswer">
                                                D. 某年7月26日渣土车因闯红灯的事故
                                            </div>

                                            <div v-if="isShowExamAnswer" class="aswerArea">
                                                <div class="aswerAreaTop">
                                                    <div class="aswerAreaTopL">正确答案：<span>D</span></div>
                                                    <div class="aswerAreaTopR">您的答案：<span>D</span></div>
                                                </div>
                                                <div class="examAx">
                                                    试题解析
                                                </div>
                                                <div class="answerT">
                                                    选项A、B和C描述的事件均是由车辆自身故障引起的，而选项D中的渣土车事故是由驾驶员违反交通规则（闯红灯）引起的，并非车辆自身故障。
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>



                            <div v-show="courseImageShowEnd" class="courseMainContentright"
                                :class="{ 'animate-content': courseImageShowEnd }">
                                <el-image
                                    src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseimage.png"
                                    style="width: 891px;height: 502px;">
                                </el-image>

                            </div>

                        </div>

                        <!-- <div class="outLineRight flexRow">
                            <div class="outLineRightTop" v-show="isShowTearchGift">
                            </div>
                            <transition name="slide-fade">
                                <div v-show="isShowImage" class="contentImage">
                                    <el-image
                                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/contentImage.png"
                                        style="width: 891px;height: 502px;">
                                    </el-image>

                                </div>
                            </transition>

                            <transition name="slide-fade">

                                <div v-show='isShowVideo' class="videoPlay">
                                    <video width="891" height="502" controls>
                                        <source
                                            src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/changjianjiashitufa.mp4"
                                            type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>

                                </div>
                            </transition>
                            <transition name="slide-fade">
                                <div v-show="isShowText" class="videoPlay" v-html="contentChapter.displayContent">

                                </div>
                            </transition>
                            <transition name="slide-fade">

                                <div v-show='isShowExam' class="videoPlay">
                                    <div class="examContent">
                                        <div class="examOptions">
                                            <div class="examOptionsTitle">
                                                下列哪起事件不是由车辆自身故障引起的？
                                                <div class="options" @click="showExamAnswer">
                                                    A. 2019年6月1日刘先生的转向系统故障
                                                </div>
                                                <div class="options" @click="showExamAnswer">
                                                    B. 2018年11月3日重型大挂车因刹车失灵的事故
                                                </div>
                                                <div class="options" @click="showExamAnswer">
                                                    C. 2016年8月19日越野车因爆胎的翻车事故
                                                </div>
                                                <div class="options" :class="isShowExamAnswer ? 'isRightColor' : ''"
                                                    @click="showExamAnswer">
                                                    D. 某年7月26日渣土车因闯红灯的事故
                                                </div>

                                                <div v-if="isShowExamAnswer" class="aswerArea">
                                                    <div class="aswerAreaTop">
                                                        <div class="aswerAreaTopL">正确答案：<span>D</span></div>
                                                        <div class="aswerAreaTopR">您的答案：<span>D</span></div>
                                                    </div>
                                                    <div class="examAx">
                                                        试题解析
                                                    </div>
                                                    <div class="answerT">
                                                        选项A、B和C描述的事件均是由车辆自身故障引起的，而选项D中的渣土车事故是由驾驶员违反交通规则（闯红灯）引起的，并非车辆自身故障。
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </transition>


                            <div v-show="isShowTextAndImage" class="videoPlay"
                                :class="isShowTextAndImage ? 'width1000' : ''">
                                <div class="leftText">
                                    {{ enText.displayContent }}
                                </div>

                                <div class="leftImage">
                                    <el-image
                                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseimage.png"
                                        style="width: 791px;height: 502px;">
                                    </el-image>

                                </div>


                            </div>
                        </div> -->

                    </div>
                    <!--学习课程视频结束-->



                    <!--最后考试开始-->

                    <div v-show="isShowEnd" class="outLineDiv">
                        <div class="outLineLeft">

                        </div>
                        <div class="outLineRight">
                            <div class="outLineRightTop">
                            </div>
                            <div class="outLineDesInfo">
                                {{ chapterEnd.displayContent }}
                            </div>
                            <div class="bottomBtnOne marginLeftZero">
                                <div class="learnBtn" @click="showContentClick">开始考试</div>
                                <div class="learnBtn">学习大纲</div>
                            </div>
                        </div>

                    </div>
                    <!--最后考试结束-->

                </div>


            </div>

            <div class="inputContent">
                <div class="deleteImage">
                    <el-image
                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/couesrDelete.png"
                        style="width: 33px;height: 33px;">
                    </el-image>
                </div>
                <input type="text" placeholder="请输入文字" style="width: 100%;height: 100%;border: none;">
                <div class="videoImage">
                    <el-image
                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseRadio.png"
                        style="width: 33px;height: 33px;">
                    </el-image>
                </div>
                <div class="fileImage">
                    <el-image
                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseUploadFile.png"
                        style="width: 33px;height: 33px;">
                    </el-image>
                </div>
                <div class="sendImage">
                    <el-image
                        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseInputSend.png"
                        style="width: 43px;height: 43px;">
                    </el-image>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            isShowTearchGiftNewFirstSHOW: false,
            isShowTearchGiftNewFirstImage: false,
            isShowTearchGiftNewFirst: false,
            courseImageShowEnd: false,
            isShowTextLeftNew: false,
            isShowTextLeftNewAnimation: false,
            coursMainExamShow: false,
            coursMainTextShow: false,
            isShowTearchGiftNew: true,
            showVideoContent: false,
            courseImageShow: false,
            isShowTearchAnimation: false,
            animateOne: false,
            isShowImageRight: false,
            msg: 'Hi，欢迎来到《紧急情况及应急处置》课程，接下来我们了解一下这堂课的课程背景。',
            isShowBG: false,
            bgMsg: '继续了解大纲。',
            isShowOutLine: false,
            outLineData: [
                {
                    title: '第一章：应急处置基础知识全面介绍',
                    content: '1.1 应急处置概念的深入解析与重要性阐述<br>1.2 事故报告的标准程序与紧急处理的关键步骤',
                    displayContent: ''
                },
                {
                    title: '第二章：车辆故障与事故的典型案例分析',
                    content: '2.1 转向系统失控情况下的应急处置策略<br>2.2 制动系统失效时的快速反应与处理方法<br>2.3 轮胎故障发生时的即时应对与预防措施<br>2.4 发动机突然熄火的诊断与现场处置技巧<br>2.5 车辆起火紧急情况下的安全逃生与扑救<br>2.6 车辆侧滑现象的成因分析与稳定控制技巧<br>2.7 车辆碰撞事故的应急反应与伤害最小化策略<br>2.8 驾驶过程中遇袭的自我保护与应急逃生方法<br>',
                    displayContent: ''
                }
            ],
            visibleChapterIndex: -1,
            typingSpeed: 50,// 打字速度，单位：毫秒
            isShowOutLineOne: false,
            chapter: {
                content: '1.应急处置的初步认识.mp4课程学习内容.',
                displayContent: ''
            },
            isShowContent: false, // 显示学习内容
            isShowContentNew: false,
            isShowVideo: false,
            isShowImage: false,
            isShowText: false,
            isShowExam: false,
            isShowTearchGift: true,
            contentChapter: {
                content: '驾驶突发事件的共性与分类 <br>- 突发事件具有不可预见性，留给驾驶员的处置时间极短。<br>- 后果往往极其严重，非死即伤。<br>- 分类：<br>- 与车辆自身问题相关，如转向失控、制动失效等。<br>- 由外部条件引起的，如自然灾害、特殊天气等',
                displayContent: ''
            },
            isShowExamAnswer: false,
            isShowTextAndImage: false,
            isShowEnd: false,
            chapterEnd: {
                content: '恭喜您已完成《紧急情况及应急处置》课程的学习，接下来我们进行一次考试。',
                displayContent: ''
            },
            enText: {
                content: '2018年11月3日，中国兰海高速上发生了一起重大交通事故。一辆载有塔吊的重型大挂车，在经过长达17公里的下坡路段时，由于频繁使用刹车制动，导致刹车失灵。在兰州南收费站附近，这辆重型大挂车与排队等候缴费的车辆发生碰撞，造成了极其严重的后果：15人不幸遇难，45人受伤。这起事故不仅暴露了车辆维护和驾驶员应对紧急情况的能力问题，也引起了对交通安全管理的广泛关注和深刻反思。',
                displayContent: ''
            }

        }

    },
    created() {
        this.getData();
    },
    mounted() {
        console.log('mounted',)
        this.writing(0)
    },
    methods: {
        isShowTearchAnimationClick() {
            this.isShowContentNew = true;
            console.log('isShowTearchAnimationClick')
            this.isShowTearchAnimation = true;
            setTimeout(() => {
                this.courseImageShow = true;
                this.isShowTearchAnimation = false;
                setTimeout(() => {
                    this.courseImageShow = false;
                    this.showVideoContent = true
                    this.isShowTearchGiftNew = false
                    document.querySelector('video').play()
                    //监听视频播放结束以后
                    document.querySelector('video').addEventListener('ended', () => {
                        this.showVideoContent = false;
                        this.isShowTearchGiftNew = true
                        this.isShowTearchAnimation = true;
                        setTimeout(() => {
                            this.isShowTearchAnimation = false;
                            this.coursMainTextShow = true;
                            this.typingEffect(this.contentChapter)
                            setTimeout(() => {
                                this.isShowTearchAnimation = false;
                                this.isShowTearchGiftNew = false
                                this.coursMainTextShow = false;

                                this.isShowTextLeftNew = true;
                                this.isShowTextLeftNewAnimation = true;
                                this.typingEffect(this.enText)
                                setTimeout(() => {
                                    this.isShowTextLeftNewAnimation = false;
                                    this.courseImageShowEnd = true

                                    setTimeout(() => {
                                        this.courseImageShowEnd = false
                                        this.isShowTextLeftNew = false;
                                        this.isShowTearchAnimation = true;
                                        this.isShowTearchGiftNew = true;
                                        setTimeout(() => {
                                            this.isShowTearchAnimation = false;
                                            this.coursMainExamShow = true;


                                        }, 6000);




                                    }, 3000);




                                }, 9000);

                            }, 9000);

                        }, 3000);




                    })




                }, 6000)
            }, 5000);
        },
        showExamAnswer() {
            this.isShowExamAnswer = true;
            // //3秒钟以后显示isShowEnd
            // this.isShowExam = false
            // this.isShowTextAndImage = true
            // this.isShowTearchGift = false
            // this.typingEffect(this.enText)
            setTimeout(() => {
                this.isShowEnd = true
                this.typingEffect(this.chapterEnd)
            }, 3000)


        },
        showContentClick() {
            this.isShowContent = true
            this.isShowImage = true
            // 3秒钟以后自动播放视频

            setTimeout(() => {
                this.isShowVideo = true
                this.isShowTearchGift = false
                this.isShowImage = false
                document.querySelector('video').play()
                //监听视频播放结束以后
                document.querySelector('video').addEventListener('ended', () => {
                    this.isShowImage = false
                    this.isShowVideo = false
                    this.isShowText = true;
                    this.typingEffect(this.contentChapter)
                    this.isShowTearchGift = true

                    // 20秒钟以后显示一个图片
                    setTimeout(() => {
                        this.isShowExam = true
                        this.isShowText = false;
                    }, 10000)
                })
            }, 3000)
        },
        learnOutLineOne() {
            this.isShowOutLineOne = true;
            this.typingEffect(this.chapter)

        },
        showOutLineAction() {
            this.isShowOutLine = true;
            const delay = 500; // 延迟时间，单位：毫秒
            const interval = 1000; // 每个章节之间的间隔时间，单位：毫秒

            let currentIndex = -1;
            const timer = setInterval(() => {
                currentIndex++;
                if (currentIndex >= this.outLineData.length) {
                    clearInterval(timer);
                    return;
                }

                setTimeout(() => {
                    this.showNextChapter();
                }, interval);
            }, delay);
        },
        isChapterVisible(index) {
            return index <= this.visibleChapterIndex;
        },
        showNextChapter() {
            if (this.visibleChapterIndex < this.outLineData.length - 1) {
                this.visibleChapterIndex++;
                this.typingEffect(this.outLineData[this.visibleChapterIndex]);
            }
        },
        typingEffect(chapter) {
            let index = 0;
            const interval = setInterval(() => {
                chapter.displayContent += chapter.content[index];
                index++;
                if (index >= chapter.content.length) {
                    clearInterval(interval);
                }
            }, this.typingSpeed);
        },

        showBGStepAction() {
            this.isShowBG = true
            this.writingBG(0)
            this.isShowTearchGiftNewFirst = true
            this.isShowTearchGiftNewFirstSHOW = true
            setTimeout(() => {
                this.isShowTearchGiftNewFirst = false
                this.isShowTearchGiftNewFirstImage = true

                setTimeout(() => {
                    // this.animateOne = false
                    // this.isShowImageRight = true
                    this.showOutLineAction()

                }, 6000)
            }, 3000)


        },
        writingBG(index) {
            const dom = document.querySelector('.courseBGInfo')
            const data = this.bgMsg.split('')
            if (index < data.length) {
                dom.innerHTML += data[index]
                setTimeout(this.writingBG, 50, ++index)
            }
        },
        writing(index) {
            const dom = document.querySelector('.topDesInfo')
            const data = this.msg.split('')
            if (index < data.length) {
                dom.innerHTML += data[index]
                setTimeout(this.writing, 50, ++index)
            }
        },
        getData() {
            console.log('getData')
        }
    }
}
</script>

<style scoped lang="scss">
.courseMainContentrighttnew {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 891px;
    min-height: 502px;
    background: #F4F7FF;
    border-radius: 15px;
    margin-left: 25px;

    .el-image {
        width: 458px;
        height: 257px;
    }

}

.courseMainContentleftnew {
    min-width: 123px;
    height: 186px;

    .imageTeacher {
        width: 123px;
        height: 186px;
        background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/1918e873-e120-4317-b824-5cb81a882c85.gif') no-repeat;
        background-size: 100% 100%;
    }
}

.teacherContentRightNew {
    display: flex;
    margin-left: 26px;
    flex: 1;


}

.animate-teachernewright {
    animation: moveToTeachernewright 2.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

@keyframes moveToTeachernewright {
    from {
        transform: translateX(450px);
    }

    to {
        transform: translateX(0px);
    }
}

.flexLeft {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    background-color: #ffffff !important;
}

.animate-teachernew {
    animation: moveToTeachernew 2.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

.animate-teacher {
    animation: moveToTeacher 2.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

.animate-content {
    animation: moveToCourseContent 2.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

@keyframes moveToTeachernew {
    from {
        transform: translateX(450px);
    }

    to {
        transform: translateX(40px);
    }
}

@keyframes moveToTeacher {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(-450px);
    }
}

@keyframes moveToCourseContent {
    from {
        transform: translateX(891px);
    }

    to {
        transform: translateX(0px);
    }
}


.courseMainContent {
    flex: 1;
    display: flex;
    justify-content: center;
    min-height: 100px;

    .courseMainContentleftnobg {
        width: 123px;
        min-height: 186px;
        margin-left: -40px;
    }

    .courseMainContentleft {
        width: 123px;
        height: 186px;
        background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/1918e873-e120-4317-b824-5cb81a882c85.gif') no-repeat;
        background-size: 100% 100%;
        margin-left: -40px;
    }

    .courseMainContentright {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 891px;
        min-height: 502px;
        background: #F4F7FF;
        border-radius: 15px;
        margin-left: 25px;



    }

    .examContent {
        width: 469px;
        height: 639px;
        background: #F4F7FF;
        border-radius: 15px;
        display: flex;
        align-items: center;

        .examOptions {
            width: 425px;
            height: 567px;
            background: #FFFFFF;
            border-radius: 14px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 15px 25px 0 20px;

            .examOptionsTitle {
                width: 360px;
                height: 56px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #202124;
                line-height: 56px;
                text-align: left;
                font-style: normal;

                .isRightColor {
                    background: rgba(26, 114, 255, 0.1) !important;
                    color: #1A72FF !important;


                }

                .options {
                    width: 375px;
                    height: 54px;
                    background: #F7F7F7;
                    border-radius: 6px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 16px;
                    color: #202124;
                    line-height: 54px;
                    text-align: left;
                    font-style: normal;
                    margin-bottom: 15px;
                    padding-left: 20px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                .aswerArea {
                    width: 375px;
                    min-height: 180px;
                    background: #F4F7FF;
                    border-radius: 6px;
                    margin-top: 5px;
                    display: flex;
                    flex-direction: column;
                    padding: 14px 20px;
                    box-sizing: border-box;

                    .answerT {
                        width: 335px;
                        height: 69px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 17px;
                        color: #202124;
                        line-height: 23px;
                        text-align: justify;
                        font-style: normal;
                    }

                    .examAx {
                        width: 72px;
                        height: 30px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 18px;
                        color: #919191;
                        line-height: 30px;
                        text-align: left;
                        font-style: normal;
                    }

                    .aswerAreaTop {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 5px;

                        .aswerAreaTopL {
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            font-size: 16px;
                            color: #202124;
                            line-height: 22px;
                            text-align: left;
                            font-style: normal;

                            span {
                                color: #1A72FF;
                            }
                        }

                        .aswerAreaTopR {
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            font-size: 16px;
                            color: #202124;
                            line-height: 22px;
                            text-align: left;
                            font-style: normal;

                            span {
                                color: #1A72FF;
                            }
                        }

                    }



                }
            }

        }
    }


}





.width100 {
    width: 100% !important;
    justify-content: center !important;
}

.animate-right {
    animation: moveToCenter 2.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}


@keyframes moveToCenter {
    from {
        transform: translateX(458px);
    }

    to {
        transform: translateX(-200px);
    }
}



.animate-left {
    animation: moveToLeft 2.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
    animation-delay: 3s;
}

@keyframes moveToLeft {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(-230px);
    }
}

.flex1 {
    flex: 1 !important;
    align-items: center;
}

.slide-fade-enter-active {
    transition: all .6s ease-out;
}

.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.width1000 {
    width: 1000px !important;
    display: flex !important;

}

.leftImage {
    margin-left: 20px;
}

.flexColum {
    display: flex;
    flex-direction: column !important;

}

.inputContent {
    width: 1200px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 30px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    padding: 0 50px;
    box-sizing: border-box;

    .deleteImage {
        width: 33px;
        height: 33px;
        background-size: 100% 100%;
        margin-right: 39px;

    }

    .videoImage {
        width: 33px;
        height: 33px;
        background-size: 100% 100%;
        margin-left: 20px;

    }

    .fileImage {
        width: 33px;
        height: 33px;
        background-size: 100% 100%;
        margin-left: 20px;

    }

    .sendImage {
        width: 43px;
        height: 43px;
        background-size: 100% 100%;
        margin-left: 50px;


    }


}


.videoPlay {
    width: 891px;
    min-height: 502px;
    margin-left: 25px;
}

.contentImage {
    width: 891px;
    height: 502px;
    background-size: 100% 100%;
    margin-left: 25px;

}

.flexRow {
    display: flex;
    flex-direction: row !important;
}

.chapter {
    margin-top: 10px;

    .chapter-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        text-align: justify;
        font-style: normal;
        margin-bottom: 10px;
    }

    p {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #1A72FF;
        line-height: 25px;
        text-align: justify;
        font-style: normal;
        padding-left: 20px;
    }
}

.outlineTitle {
    width: 1037px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 700;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    text-align: justify;
    font-style: normal;
}

.marginLeftZero {
    margin-left: 0 !important;

}

.outLineDiv {
    display: flex;
    margin-top: 40px;
    margin-left: 40px;

    .outLineLeft {
        width: 57px;
        height: 57px;
        background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseTImage.png') no-repeat center;
        background-size: 100% 100%;
    }

    .outLineRight {
        display: flex;
        flex-direction: column;
        margin-left: 26px;

        .outLineRightTop {
            width: 123px;
            height: 186px;
            background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/1918e873-e120-4317-b824-5cb81a882c85.gif') no-repeat center;
            background-size: 100% 100%;
        }


        .outLineDesInfo {
            min-width: 666px;
            min-height: 30px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            margin-top: 23px;
        }

        .bottomBtnOne {
            display: flex;
            margin-top: 20px;
            margin-left: 123px;

            .learnBtn {
                width: 122px;
                height: 32px;
                background: #1A72FF;
                border-radius: 3px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 32px;
                text-align: left;
                font-style: normal;
                text-align: center;
                margin-right: 76px;
                cursor: pointer;
            }
        }

    }
}


.bottomBtnOne {
    display: flex;
    margin-top: 20px;
    margin-left: 123px;

    .learnBtn {
        width: 122px;
        height: 32px;
        background: #1A72FF;
        border-radius: 3px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        text-align: center;
        margin-right: 76px;
        cursor: pointer;
    }
}

.mainPage {
    background-color: #f4f7fa;
    width: 100%;
    padding-bottom: 197px;
    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/course/coursePageBg.png') no-repeat center;

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        background-color: #fff;
        padding: 30px 20px 56px;
        box-sizing: border-box;
        margin-top: 40px;
        border-radius: 14px;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        width: 1200px;
        min-height: 100vh;

        .topNav {
            display: flex;
            align-items: center;
            margin-bottom: 42px;
            height: 36px;
            width: 100%;

            img {
                width: 32px;
                height: 32px;
            }

            span {
                width: 432px;
                height: 30px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 22px;
                color: #333333;
                line-height: 30px;
                text-align: left;
                font-style: normal;
                margin-left: 17px;
            }

            dd {
                width: 58px;
                height: 24px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #245FFF;
                width: 45px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 15px;
                color: #1A72FF;
                line-height: 24px;
                text-align: center;
                font-style: normal;
                padding: 0 7px;
                margin-left: 16px;
            }
        }

        .marginTOP23 {
            margin-top: 23px;
        }

        .marginTOP25 {
            margin-top: 25px;
        }

        .teacherContent {
            margin-left: 40px;
            display: flex;
            min-height: 297px;

            .teacherContentLeft {
                width: 57px;
                height: 57px;
                background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/courseTImage.png') no-repeat center;
                background-size: 100% 100%;
            }

            .courseBGInfo {
                min-width: 144px;
                height: 30px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                line-height: 30px;
                text-align: left;
                font-style: normal;
                margin-top: 23px;
            }

            .topFlex {
                display: flex;

                .imgbg {
                    width: 458px;
                    height: 257px;
                    background-size: 100% 100%;

                }
            }

            .teacherContentRightTopC {
                display: flex;
                flex-direction: column;
            }

            .teacherContentRight {
                display: flex;
                flex-direction: column;
                margin-left: 26px;


                .teacherContentRightTop {
                    width: 123px;
                    height: 186px;
                    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/ioCourse/1918e873-e120-4317-b824-5cb81a882c85.gif') no-repeat center;
                    background-size: 100% 100%;
                }

                .topDesInfo {
                    min-width: 666px;
                    height: 30px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    line-height: 30px;
                    text-align: left;
                    font-style: normal;
                    margin-top: 23px;
                }

                .bottomBtn {
                    display: flex;
                    margin-top: 20px;

                    .learnBtn {
                        width: 122px;
                        height: 32px;
                        background: #1A72FF;
                        border-radius: 3px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 32px;
                        text-align: left;
                        font-style: normal;
                        text-align: center;
                        cursor: pointer;
                    }

                    .jumpBtn {
                        width: 249px;
                        height: 32px;
                        background: #EDEDED;
                        border-radius: 3px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 16px;
                        color: #333333;
                        line-height: 32px;
                        text-align: center;
                        font-style: normal;
                        margin-left: 63px;
                        cursor: pointer;

                    }
                }

            }



        }
    }
}
</style>
